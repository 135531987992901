import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { styles } from './Modal.tailwind';

const Modal = ({
  isOpen=true,
  handleClose,
  title="",
  titleNode=null,
  titleCenter=false,
  size="medium",
  children,
  initialFocusRef=null,
  closeOnClickaway=true,
  preventDefaultClose=false, // prevents close on click outside or 'esc'
  hasCustomTitleSection=false,
  overflowY="auto"
}) => {
  const cancelButtonRef = useRef();

  let _size = size;
  const sizeEnums = ["small", "medium", "large", "xl", "xxl"];
  if (!sizeEnums.includes(_size)) _size = "medium";
  
  let overflowYClassName = "overflow-y-auto";
  if (overflowY === "visible") overflowYClassName = "overflow-y-visible";

  const maxWidthClass = styles.sizes[_size];

  const noOp = () => {};
  const shouldClose = preventDefaultClose ? noOp : handleClose;
  
  return (
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30 overflow-y-auto bg-gray-200 bg-opacity-80"
          initialFocus={initialFocusRef || cancelButtonRef}
          open={isOpen}
          onClose={shouldClose}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {closeOnClickaway ? <Dialog.Overlay className="fixed inset-0" /> : <div className="fixed inset-0" />}
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className={`xl:max-w-2xl md:max-w-xl max-w-md inline-block w-full py-10 px-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl`}>
                <Dialog.Title
                  as={hasCustomTitleSection ? "div" : "h3"}
                  className={`text-lg py-4 font-medium leading-6 text-gray-900 ${titleCenter ? 'text-center' : ''}`}
                >
                  {hasCustomTitleSection ? titleNode : title}
                </Dialog.Title>
                <div className={`mt-2 max-h-3/4-screen ${overflowYClassName}`}>
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
  );
}

export default Modal;

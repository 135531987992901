"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPluralModifiers = getPluralModifiers;
function getPluralModifiers(num) {
  if (typeof num !== 'number') console.warn('getPluralModifiers: num is not a number');
  return {
    isOrAre: num === 1 ? 'is' : 'are',
    thisOrThese: num === 1 ? 'this' : 'these',
    pluralSuffix: num === 1 || num === 0 ? '' : 's'
  };
}
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';

import './index.css';
import 'gmi-tailwind';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { AuthContextProvider } from './shared/contexts/AuthContext';
import { FeedbackContextProvider } from './shared/contexts/FeedbackContext';
import { RedirectContextProvider } from 'project/contexts/RedirectContext';

if (process.env.NODE_ENV !== 'development' && 1==0) {
  Sentry.init({
    dsn: "https://4fedbbfaeaaf419c9183ae20f61c90de@o1327121.ingest.sentry.io/4504012961021952",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <RedirectContextProvider>
      <ErrorBoundary>
        <FeedbackContextProvider>
          <Router>
            <AuthContextProvider>
              <App />
            </AuthContextProvider>
          </Router>
        </FeedbackContextProvider>
      </ErrorBoundary>
    </RedirectContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
